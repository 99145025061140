var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-item","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":"","hide-footer":"","centered":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"d-flex flex-column my-2"},[_c('h2',[_vm._v(_vm._s(_vm.actionText))]),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.withoutLote}},[_vm._v(" Lote não existente no estoque. ")]),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.item.errorApi.error.length}},[_vm._v(" "+_vm._s(_vm.item.errorApi.error)+" ")]),_c('validation-observer',{ref:"itemRules"},[_c('b-form',[_c('b-row',{staticClass:"pt-1"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Campanha*","label-for":"campanha-input"}},[_c('validation-provider',{attrs:{"name":"campanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"campanha-input","options":_vm.campanhaSelect,"label":"campanha_descricao","placeholder":"Selecione uma campanha","clearable":true},on:{"input":_vm.searchUnidades},model:{value:(_vm.item.campanha),callback:function ($$v) {_vm.$set(_vm.item, "campanha", $$v)},expression:"item.campanha"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.campanha)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.campanha)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Unidade*","label-for":"unidade-input"}},[_c('validation-provider',{attrs:{"name":"unidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"unidade-input","options":_vm.unidadeSelect,"label":"descricao_unidade","placeholder":"Selecione uma unidade","clearable":true,"disabled":_vm.disabled.unidade},model:{value:(_vm.item.unidade),callback:function ($$v) {_vm.$set(_vm.item, "unidade", $$v)},expression:"item.unidade"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])}),(_vm.item.errorApi.id_unidade_origem)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.id_unidade_origem)+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Lote","label-for":"lote-input"}},[_c('validation-provider',{attrs:{"name":"lote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lote-input","placeholder":"N° do lote","maxLength":"15","disabled":_vm.disabled.lote},on:{"input":_vm.searchLote},model:{value:(_vm.item.lote),callback:function ($$v) {_vm.$set(_vm.item, "lote", $$v)},expression:"item.lote"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.id_lote)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.id_lote)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade","label-for":"quantidade-input"}},[_c('validation-provider',{attrs:{"name":"quantidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],attrs:{"id":"quantidade-input","placeholder":"N° de doses"},on:{"keydown":function($event){_vm.item.quantidade.length == 0 && $event.key === '0' && $event.preventDefault()}},model:{value:(_vm.item.quantidade),callback:function ($$v) {_vm.$set(_vm.item, "quantidade", $$v)},expression:"item.quantidade"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.quantidade)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.quantidade)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":'Motivo de '+ _vm.action +'*',"label-for":"inclusao-input"}},[_c('validation-provider',{attrs:{"name":"motivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vacina-input","options":_vm.typeSelectedMotive,"placeholder":"Selecione um motivo","clearable":true},model:{value:(_vm.item.motivo),callback:function ($$v) {_vm.$set(_vm.item, "motivo", $$v)},expression:"item.motivo"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.motivo)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.motivo)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Vacina*","label-for":"vacina-input"}},[_c('validation-provider',{attrs:{"name":"vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vacina-input","options":_vm.vacinaSelect,"label":"nome_tecnico","placeholder":"Selecione uma vacina","clearable":true,"disabled":_vm.disabled.vacina},model:{value:(_vm.item.vacina),callback:function ($$v) {_vm.$set(_vm.item, "vacina", $$v)},expression:"item.vacina"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fabricante*","label-for":"fabricante-input"}},[_c('validation-provider',{attrs:{"name":"fabricante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"fabricante-input","options":_vm.fabricanteSelect,"label":"descricao","placeholder":"Selecione o fabricante","clearable":true,"disabled":_vm.disabled.fabricante},model:{value:(_vm.item.fabricante),callback:function ($$v) {_vm.$set(_vm.item, "fabricante", $$v)},expression:"item.fabricante"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de frasco*","label-for":"dose-input"}},[_c('validation-provider',{attrs:{"name":"frasco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"dose-input","options":_vm.frascoSelect,"label":"descricao","placeholder":"Selecione um frasco","clearable":true,"disabled":_vm.disabled.frasco},model:{value:(_vm.item.frasco),callback:function ($$v) {_vm.$set(_vm.item, "frasco", $$v)},expression:"item.frasco"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Validade*","label-for":"validade-input"}},[_c('validation-provider',{attrs:{"name":"validade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"validade-input","placeholder":"00/00/0000","autocomplete":"off","disabled":_vm.disabled.validade},on:{"input":_vm.setInput},model:{value:(_vm.input.validade),callback:function ($$v) {_vm.$set(_vm.input, "validade", $$v)},expression:"input.validade"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de validade","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setPicker},model:{value:(_vm.item.validade),callback:function ($$v) {_vm.$set(_vm.item, "validade", $$v)},expression:"item.validade"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Observações","label-for":"observacoes-input"}},[_c('validation-provider',{attrs:{"name":"observacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"observacoes-input","placeholder":"Observações..."},model:{value:(_vm.item.observacao),callback:function ($$v) {_vm.$set(_vm.item, "observacao", $$v)},expression:"item.observacao"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1),(_vm.item.errorApi.observacao)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.observacao)+" ")]):_vm._e()],1)],1)],1)],1)],1),_c('section',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-button',{staticClass:"mr-2 mb-2-sm",attrs:{"id":"save-entrada","variant":"custom-blue","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.confirmAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.actionButton))]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-entrada","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.cancelAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }