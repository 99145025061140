export default {
    handleErrosAjuste(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.campanha = error.id_campanha ? error.id_campanha[0] : '';
        errorForm.motivo = error.motivo ? error.motivo[0] : '';
        errorForm.observacao = error.observacao ? error.observacao[0] : '';
        errorForm.numero_nota_fiscal = error.numero_nota_fiscal ? error.numero_nota_fiscal[0] : '';
        errorForm.id_unidade_origem = error.id_unidade_origem ? error.id_unidade_origem[0] : '';
        errorForm.lotes = error.movimentacao_item ? error.movimentacao_item[0] : '';
        errorForm.error = '';
        errorForm.id_lote = error['movimentacao_item.0.id_lote'] ? error['movimentacao_item.0.id_lote'][0] : null;
        errorForm.quantidade = error['movimentacao_item.0.quantidade'] ? error['movimentacao_item.0.quantidade'][0] : null;

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}