<template>
  <b-modal
        id="add-new-item"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-model="showModal"
    >
        <div class="d-flex flex-column my-2">
            <h2>{{actionText}}</h2>
            <b-alert class="p-1" variant="danger" :show="withoutLote">
                Lote não existente no estoque.
            </b-alert>
            <b-alert class="p-1" variant="danger" :show="item.errorApi.error.length">
                {{item.errorApi.error}}
            </b-alert>
            <validation-observer ref="itemRules">
                <b-form>
                    <b-row class="pt-1">
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Campanha*"
                            label-for="campanha-input">
                            <validation-provider
                                #default="{ errors }"
                                name="campanha"
                                rules="required"
                                >
                                    <v-select 
                                    id="campanha-input"
                                    v-model="item.campanha"
                                    :options="campanhaSelect"
                                    label="campanha_descricao"
                                    placeholder="Selecione uma campanha"
                                    :clearable="true"
                                    @input="searchUnidades"
                                    >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="item.errorApi.campanha" class="text-danger">
                                    {{ item.errorApi.campanha }}
                                </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Unidade*"
                            label-for="unidade-input">
                            <validation-provider
                                #default="{ errors }"
                                name="unidade"
                                rules="required"
                                >
                                    <v-select 
                                    id="unidade-input"
                                    v-model="item.unidade"
                                    :options="unidadeSelect"
                                    label="descricao_unidade"
                                    placeholder="Selecione uma unidade"
                                    :clearable="true"
                                    :disabled="disabled.unidade"
                                    >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                                <small v-if="item.errorApi.id_unidade_origem" class="text-danger">
                                    {{ item.errorApi.id_unidade_origem }}
                                </small>
                            </b-form-group>
                       </b-col>
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Lote"
                            label-for="lote-input">
                            <validation-provider
                                #default="{ errors }"
                                name="lote"
                                rules="required"
                                >
                                <b-form-input
                                    id="lote-input"
                                    placeholder="N° do lote"
                                    v-model="item.lote"
                                    maxLength="15"
                                    @input="searchLote"
                                    :disabled="disabled.lote"
                                />
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="item.errorApi.id_lote" class="text-danger">
                                    {{ item.errorApi.id_lote }}
                                </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Quantidade"
                            label-for="quantidade-input">
                            <validation-provider
                                #default="{ errors }"
                                name="quantidade"
                                rules="required"
                                >
                                <b-form-input
                                    id="quantidade-input"
                                    v-model="item.quantidade"
                                    placeholder="N° de doses"
                                    @keydown="item.quantidade.length == 0 && $event.key === '0' && $event.preventDefault()"
                                    v-mask="'#######'"
                                />
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                <small v-if="item.errorApi.quantidade" class="text-danger">
                                    {{ item.errorApi.quantidade }}
                                </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                    </b-row>
                    <b-row>    
                       <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            :label="'Motivo de '+ action +'*'"
                            label-for="inclusao-input">
                            <validation-provider
                                #default="{ errors }"
                                name="motivo"
                                rules="required"
                                >
                                <v-select 
                                id="vacina-input"
                                v-model="item.motivo"
                                :options="typeSelectedMotive"
                                placeholder="Selecione um motivo"
                                :clearable="true"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="item.errorApi.motivo" class="text-danger">
                                    {{ item.errorApi.motivo }}
                                </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                      </b-row>
                       <b-row>
                       <b-col lg="12" md="12" sm="12">
                           <b-form-group 
                            label="Vacina*"
                            label-for="vacina-input">
                            <validation-provider
                                #default="{ errors }"
                                name="vacina"
                                rules="required"
                                >
                                <v-select 
                                id="vacina-input"
                                v-model="item.vacina"
                                :options="vacinaSelect"
                                label="nome_tecnico"
                                placeholder="Selecione uma vacina"
                                :clearable="true"
                                :disabled="disabled.vacina"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                       </b-row>
                       <b-row>
                       <b-col lg="12" md="12" sm="12">
                           <b-form-group 
                            label="Fabricante*"
                            label-for="fabricante-input">
                            <validation-provider
                                #default="{ errors }"
                                name="fabricante"
                                rules="required"
                                >
                                <v-select 
                                id="fabricante-input"
                                v-model="item.fabricante"
                                :options="fabricanteSelect"
                                label="descricao"
                                placeholder="Selecione o fabricante"
                                :clearable="true"
                                :disabled="disabled.fabricante"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                        </b-row>
                       <b-row>
                       <b-col lg="6" md="12" sm="12">
                           <b-form-group 
                            label="Tipo de frasco*"
                            label-for="dose-input">
                            <validation-provider
                                #default="{ errors }"
                                name="frasco"
                                rules="required"
                                >
                                <v-select 
                                id="dose-input"
                                v-model="item.frasco"
                                :options="frascoSelect"
                                label="descricao"
                                placeholder="Selecione um frasco"
                                :clearable="true"
                                :disabled="disabled.frasco"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                                label="Validade*"
                                label-for="validade-input"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="validade"
                                rules="required"
                                >
                                <b-input-group>
                                    <b-form-input
                                        class="form-control"
                                        id="validade-input"
                                        v-model="input.validade"
                                        v-mask="'##/##/####'"
                                        placeholder="00/00/0000"
                                        autocomplete="off"
                                        @input="setInput"
                                        :disabled="disabled.validade"
                                    />
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            class="form-control d-flex align-items-center"
                                            locale="pt-BR"
                                            v-model="item.validade"
                                            button-only
                                            right
                                            :hide-header="true"
                                            label-help="Selecione a data de validade"
                                            button-variant="custom-blue"
                                            clearable
                                            @input="setPicker"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>
                      </b-row>
                       <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Observações"
                            label-for="observacoes-input">
                            <validation-provider
                                #default="{ errors }"
                                name="observacao"
                                >
                                <b-form-input
                                    id="observacoes-input"
                                    v-model="item.observacao"
                                    placeholder="Observações..."
                                />
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                </validation-provider>
                            </b-form-group>
                        <small v-if="item.errorApi.observacao" class="text-danger">
                                {{ item.errorApi.observacao }}
                            </small>
                        </b-col>
                       </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section class="d-flex justify-content-center mb-1">
                <b-button id="save-entrada" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="confirmAction"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">{{actionButton}}</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="cancelAction"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
    </b-modal>
</template>

<script>
import { BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard,
BFormDatepicker, BInputGroup, BInputGroupAppend,BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import handleErrorsService from '@/views/custom-pages/GerenciarEstoqueLote/AjusteEstoque/Services/HandleErrors';
import { required } from '@validations'
import vSelect from 'vue-select';
import moment from 'moment';

export default {
  components: {
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard, vSelect,
BFormDatepicker, BInputGroup, BInputGroupAppend,BAlert,
ValidationProvider, ValidationObserver
    },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    typeModal: {
      type: String,
      default: '',
    },
    campanhaSelect: {
        type: Array,
        default() {
            return []
        }
    },
    vacinaSelect: {
        type: Array,
        default() {
            return []
        }
    },
    fabricanteSelect: {
      type: Array,
        default() {
            return []
        }
    },
    frascoSelect: {
      type: Array,
      default() {
            return []
        }
    }
  },
  data() {
    return {
        required,
        item: {
            campanha: '',
            unidade: '',
            lote: '',
            quantidade: '',
            motivo: '',
            vacina: '',
            fabricante: '',
            frasco: '',
            validade: '',
            observacao: '',
            id_tipo_movimentacao: '',
            id_lote: '',
            errorApi: {
                campanha: '',
                numero_nota_fiscal: '',
                id_unidade_origem: '',
                motivo: '',
                observacao: '',
                lotes: '',
                id_lote: '',
                quantidade: '',
                error: '',
            }
        },
        disabled: {
            unidade: true,
            vacina: true,
            fabricante: true,
            frasco: true,
            validade: true,
            lote: true
        },
        action: '',
        actionText: '',
        actionButton: '',
        typeSelectedMotive: [],
        input: {
            validade: '',
        },
        withoutLote: false,
        saving: false,
        unidadeSelect: [],
    }
  },
  methods: {
    confirmAction(modalEvent) {
        this.$refs.itemRules.validate().then(success => {
            if (success) {
            modalEvent.preventDefault();
            this.saving = true;

            const parameters = this.prepareDataToSave();

            this.$http.post(this.$api.movimentacao(), parameters)
            .then(() => {
                this.saving = false;
                this.$emit('confirmed', modalEvent);
                this.emptyForm();
                this.$refs.itemRules.reset();
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosAjuste(data, this.item.errorApi);
                this.saving = false;
            })
            }
        })
    },
    cancelAction(modalEvent) {
        modalEvent.preventDefault();
        this.emptyForm();
        this.$refs.itemRules.reset();
        this.$emit('canceled', modalEvent);
    },
    prepareDataToSave(){
        return {
            id_campanha: this.item.campanha.id_campanha,
            id_unidade_origem: this.item.unidade.id_unidade,
            id_tipo_movimentacao: this.item.id_tipo_movimentacao,
            numero_nota_fiscal: this.prepareNotaFiscalNumber(),
            observacao: this.item.observacao,
            motivo: this.item.motivo,
            movimentacao_item: [{
                id_lote: this.item.id_lote,
                quantidade: this.item.quantidade
            }]
        };
    },
    prepareNotaFiscalNumber(){
        return (this.item.unidade.descricao_unidade)
        .substring(0,2)
        .toUpperCase()
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '') 
        + moment().format('YYMDDHHmmss')
    },
    setPicker() {
        this.input.validade = moment(this.item.validade).format('DD/MM/YYYY');
    },
    setInput() {
        this.item.validade = moment(this.input.validade, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    emptyForm(){
        this.item.campanha = '';
        this.item.unidade = '';
        this.item.lote = '';
        this.item.quantidade = '';
        this.item.motivo = '';
        this.item.vacina = '';
        this.item.fabricante = '';
        this.item.frasco = '';
        this.item.validade = '';
        this.item.observacao = '';
        this.item.id_lote = '';

        this.item.errorApi.error = '';
        this.item.errorApi.campanha = '';
        this.item.errorApi.numero_nota_fiscal = '';
        this.item.errorApi.id_unidade_origem = '';
        this.item.errorApi.motivo = '';
        this.item.errorApi.observacao = '';
        this.item.errorApi.lotes = '';
        this.item.errorApi.id_lote = '';
        this.item.errorApi.quantidade = '';
        this.item.errorApi.error = '';

    },
    async searchUnidades(){
        if(!this.item.campanha){
            this.unidadeSelect = [];
            this.item.unidade = null;
            this.disabled.unidade = true;
            return;
        }
        await this.$http.get(this.$api.unidadeCampanhaParameter(this.item.campanha.id_campanha)).then(({ data }) => {
            this.unidadeSelect = data.sort((a, b) => a.descricao_unidade.localeCompare(b.descricao_unidade));
            if(this.unidadeSelect.length == 1){
                this.item.unidade = this.unidadeSelect[0];
            }
            this.disabled.unidade = false;
        });
    },
    searchLote(){
        this.item.lote = this.item.lote.toUpperCase()

        if(this.item.lote.length == 0){
            this.withoutLote = false;
            return;
        }

        const params = {
            lote: this.item.lote,
            id_unidade: this.item.unidade.id_unidade
        };

        this.$http.get(this.$api.lote(), {params})
        .then(({ data }) => {
            if(data.data.length > 0) {
                this.withoutLote = false;
                const lote = data.data[0]
                
                this.item.id_lote = lote.id_lote;
                this.item.vacina = {
                    id_vacina: lote.id_vacina,
                    nome_tecnico: lote.nome_tecnico_vacina
                }
                this.item.fabricante = {
                    id_fabricante: lote.id_fabricante,
                    descricao: lote.descricao_fabricante,
                }
                this.item.frasco = {
                    id_frasco: lote.id_frasco,
                    descricao: lote.descricao_frasco,
                }
                this.item.validade = lote.validade;
                this.item.valor_unitario = lote.nota_fiscal_itens[0].valor_unitario;

                this.setPicker();
                this.disabled.fabricante = true;
                this.disabled.frasco = true;
                this.disabled.validade = true;
            }else{
                this.withoutLote = true;
                this.item.fabricante = '';
                this.item.vacina = '';
                this.item.frasco = '';
                this.item.validade = '';
                this.$refs.itemRules.reset();
                this.setPicker();

            }
        });
    }
  },
  watch: {
    typeModal(val) {
        if(val == 'adicao'){
            this.typeSelectedMotive = ['Remanescente', 'Outros']
            this.action = 'inclusão';
            this.actionText = 'Adicionar Item ao Estoque';
            this.actionButton = 'Incluir item';
            this.item.id_tipo_movimentacao = 3;
        }else{
            this.typeSelectedMotive = ['Não aplicado', 'Danificação', 'Validade expirada', 'Refrigeração inadequada', 'Outros']
            this.action = 'descarte';
            this.actionText = 'Remover Item do Estoque';
            this.actionButton = 'Remover item';
            this.item.id_tipo_movimentacao = 2;
        }
    },

    'item.unidade': function() {
      if (this.item.unidade) {
        this.disabled.lote = false;
      } else {
        this.disabled.lote = true;
        this.item.lote = ''
      }
    }

  }

}
</script>

<style scoped>
    .bloco{
      height: 200px;
    }
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }
</style>
