<template>
    <div class="content-wrapper">
        <ActionEstoque 
        :showModal="modalAction.showModal"
        :typeModal="modalAction.typeModal"
        :campanhaSelect="campanhaSelect"
        :vacinaSelect="vacinaSelect"
        :fabricanteSelect="fabricanteSelect"
        :frascoSelect="frascoSelect"
        @confirmed="openModalSucesso"
        @canceled="closeModalAction"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="closeModalSucesso"
        />

        <PageHeader screenName="Ajuste de Estoque" :linkItems="linkItems"/>
        <main class="card hidden_overflow_tabela">
            <b-row class="p-2">
                <b-col lg="12" md="12" sm="12">
                <b-button @click="openModalAction('adicao')" variant="primary-button" class="mr-1">
                   <feather-icon
                       icon="PlusIcon"
                       class="mr-50"  
                   />
                   <span class="align-middle">Adicionar ao Estoque</span>
               </b-button>
                <b-button @click="openModalAction('descarte')" variant="primary-button">
                <feather-icon
                    icon="MinusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Remover do Estoque</span>
               </b-button>
               </b-col>
            </b-row>
           <b-row class="pt-1 px-2">
               <b-col lg="3" md="6" sm="7">
                    <b-form-group 
                    label="Unidade Operacional"
                    label-for="unidade-operacional-input"
                    label-class="font_size_label_estoque"
                    >
                        <v-select 
                        id="unidade-operacional-input"
                        v-model="search.unidade_operacional"
                        :options="unidadeSelect"
                        label="descricao_unidade"
                        placeholder="Selecione uma unidade"
                        :clearable="true"
                        >
                        <span slot="no-options">Nenhuma opção selecionável.</span>
                        </v-select>
                    </b-form-group>
               </b-col>
               <b-col lg="3" md="6" sm="7">
                    <b-form-group 
                    label="Tipo do ajuste"
                    label-for="tipo-input"
                    label-class="font_size_label_estoque"
                    >
                        <v-select
                            id="tipo-input"
                            multiselect
                            multiple
                            v-model="search.tipo"
                            label="descricao"
                            variant="custom"
                            placeholder="Selecione um ou mais"
                            :options="tipoSelect"
                        >
                            <span slot="no-options">Nenhuma opção selecionável.</span>
                        </v-select>
                    </b-form-group>
               </b-col>
               <b-col lg="3" md="6" sm="7">
                    <b-form-group 
                        label="Data do ajuste*"
                        label-for="data-ajuste-input"
                        label-class="font_size_label_estoque"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="data-ajuste-input"
                                v-mask="'##/##/####'"
                                v-model="input.data_ajuste"
                                placeholder="00/00/0000"
                                autocomplete="off"
                                @input="setInput"
                            />
                            <b-input-group-append>
                                <b-form-datepicker
                                    class="form-control d-flex align-items-center"
                                    locale="pt-BR"
                                    v-model="search.data_ajuste"
                                    button-only
                                    right
                                    :hide-header="true"
                                    label-help="Selecione a data de emissão"
                                    button-variant="custom-blue"
                                    clearable
                                    @input="setPicker"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
               </b-col>
               <b-col lg="3" md="6" sm="7">
                    <b-form-group 
                    label="Lote"
                    label-for="lote-input"
                    label-class="font_size_label_estoque"
                    >
                        <b-form-input
                            id="lote-input"
                            v-model="search.lote"
                            placeholder="N° Lote do frasco"
                        />
                    </b-form-group>
               </b-col>
               </b-row>
               <b-row class="justify-content-center">
                   <b-button @click="filter" variant="primary-button">
                       <feather-icon
                           icon="SearchIcon"
                           class="mr-50"  
                       />
                       <span class="align-middle">Pesquisar</span>
                   </b-button>
 
                   <b-button type="reset" class="ml-2 cor_botao" @click="cleanSearch" variant="outline-primary">
                       <feather-icon
                           icon="XIcon"
                           class="mr-50"  
                       />
                       <span class="align-middle">Limpar</span>
                   </b-button>
           </b-row>
           <b-row class="mx-2 mt-2">
             <b-col
                lg="3"
                md="6"
            >
                <InfoCard :title="'Adição de doses'" :oneBlock="true" :data="info.adicao"/>
            </b-col>
            <b-col
                lg="9"
                md="12"
            >
                <InfoCard :title="'Descarte de doses'" :oneBlock="true" :data="info.descarte"/>
            </b-col>
           </b-row>
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <hr>
            <div>
                <section class="filters pb-1 px-2" v-if="!loadingData">
                    <b-row align-h="between">
                        <b-col lg="3" md="4" sm="5" class="col-12 mb-1 mb-sm-0">
                            <div class="d-flex justify-center-center align-items-center">
                                <span class="mr-1" for="tableSize">Mostrar</span>
                                <v-select 
                                    id="tableSize"
                                    v-model="paginationData.defaultSize"
                                    @input="updateTableSize($event)"
                                    :options="table.optionsSize"
                                    :clearable="false"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </section>
                <section class="list-colaborador-import" v-if="!loadingData">
                    <b-table
                        id="tableColaboradorImport"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        @sort-changed="ordenarColuna"
                        no-local-sorting
                        :no-sort-reset="true"
                    >
                        <template #cell(codigo)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                #{{ row.item.codigo }}
                            </span>
                        </template>
                        <template #cell(tipo_movimentacao)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            v-if="row.item.tipo_movimentacao == 'Adicao'"
                            >
                               Adição
                            </span>
                            <span v-else>
                                Descarte
                            </span>
                        </template>
                        <template #cell(cadastro_movimentacao)="row">
                            <span class="font-weight-bold">
                                {{ formatDate(row.item.cadastro_movimentacao, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(numero_lote)="row">
                            <span class="font-weight-bold">
                                {{ row.item.itens.length ? row.item.itens[0].lote_unidade_origem.lote.numero_lote : '-' }}
                            </span>
                        </template>
                        <template #cell(validade)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ formatDate(row.item.itens.length ? row.item.itens[0].lote_unidade_origem.lote.validade : moment(), 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(quantidade)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ row.item.itens.length ? row.item.itens[0].quantidade : '-' }}
                            </span>
                        </template>
                        <template #cell(origem)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ row.item.itens.length ? row.item.itens[0].lote_unidade_origem.unidade.descricao : '-' }}
                            </span>
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                id="view-ajuste"
                                role="button"
                                class="text-custom-blue"
                                icon="EyeIcon"
                                size="18"
                                @click.prevent="redirectToShow(row.item)"
                            />
                        </template>
                    </b-table>
                    <div class="pb-2 px-1">
                        <span v-if="without" class="ml-1">Nenhum ajuste encontrado</span>
                        <b-col sm="12" class="mt-2">
                            <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                        </b-col>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, VBTooltip, BSpinner, BCard,  BFormDatepicker, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ActionEstoque from '@/views/components/custom/modals/estoque/Action-estoque.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
    title: 'Ajustes de estoque',
    
    components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfoCard,
    ActionEstoque,
    SucessoModal
},

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Ajuste de Estoque',
                    active: true
                }
            ],
            alert: {
                errorMessage: '',
                show: false
            },
            modalAction: {
                showModal: false,
                typeModal: ''
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            moment,
            filtering: false,
            loadingData: true,
            without: false,
            searchTerm: '',
            table: {
                fields: [
                    {key: "codigo", label: "AJUSTE", sortable: true, class: 'mw-150'},
                    {key: "tipo_movimentacao", label: "TIPO", sortable: true, class: 'mw-150'},
                    {key: "cadastro_movimentacao", label: "DATA DO AJUSTE", sortable: true, class: 'mw-150'},
                    {key: "numero_lote", label: "LOTE", sortable: true, class: 'mw-150'},
                    {key: "validade", label: "VALIDADE", sortable: true, class: 'mw-150'},
                    {key: "quantidade", label: "QT. DE DOSES", sortable: true, class: 'mw-150'},
                    {key: "origem", label: "U. OPERACIONAL", sortable: true, class: 'mw-150'},
                    {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-150 text-center col-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'id_movimentacao',
                order: 'desc'
            },
            paginationData: {
                currentPage: 1,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            info: {
                adicao: [
                    {
                    icon: 'ZoomInIcon',
                    color: 'light-success',
                    title: 0,
                    subtitle: 'Remanescentes',
                    },
                    {
                    icon: 'PlusIcon',
                    color: 'light-danger',
                    title: 0,
                    subtitle: 'Outros',
                    },    
                ],
                descarte: [
                    {
                    icon: 'XCircleIcon',
                    color: 'light-primary',
                    title: 0,
                    subtitle: 'Não aplicado',
                    },
                    {
                    icon: 'ShieldOffIcon',
                    color: 'light-custom-blue',
                    title: 0,
                    subtitle: 'Danificação',
                    },
                    {
                    icon: 'ThumbsDownIcon',
                    color: 'light-success',
                    title: 0,
                    subtitle: 'Validade expirada',
                    },
                    {
                    icon: 'ThermometerIcon',
                    color: 'light-warning',
                    title: 0,
                    subtitle: 'Refrigeração inadequada',
                    },
                    {
                    icon: 'PlusIcon',
                    color: 'light-danger',
                    title: 0,
                    subtitle: 'Outros',
                    },       
                ],
            },
            search: {
                unidade_operacional: '',
                data_ajuste: '',
                tipo: '',
                lote: '',
            },
            input: {
                data_ajuste: ''
            },
            saving: false,
            tipoSelect: [
                {
                    descricao: 'Adição',
                    id_tipo_movimentacao: 3,
                },
                {
                    descricao: 'Remoção',
                    id_tipo_movimentacao: 2,
                }
            ],
            unidadeSelect: [],
            campanhaSelect: [],
            vacinaSelect: [],
            fabricanteSelect: [],
            frascoSelect: []
        }
    },
    mounted() {
        this.loadAllInfo();
        this.loadCardsInfo();
        this.loadData();
    },

    methods: {
        async loadAllInfo() {
            const parameters = {
                ativo: true
            }
            await this.$http.get(this.$api.campanha(), { params: {situacao:["Em andamento"]} }).then(({ data }) => {
                this.campanhaSelect = data;
            });

            await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
                this.unidadeSelect = data;
            });

            await this.$http.get(this.$api.fabricante(), { params: parameters }).then(({ data }) => {
                this.fabricanteSelect = data;
            });

            await this.$http.get(this.$api.frasco(), { params: parameters }).then(({ data }) => {
                this.frascoSelect = data;
            });

        },
        searchVacinas(){
            const parameters = {
                ativo: true
            }
            this.$http.get(this.$api.saveAndGetVacina(), { params: parameters }).then(({ data }) => {
                this.vacinaSelect = data.data;
            });
        },
        loadCardsInfo(){
            this.$http.get(this.$api.tipoMovimentacao(), 
            {
                params: {
                    id_unidade: this.search.unidade_operacional ? this.search.unidade_operacional.id_unidade : ''
                }
            }).then(({ data }) => {
                data.map(item => {
                    if(item.id_tipo_movimentacao == 3){
                        this.info.adicao[0].title = item.remanescente ?? 0;
                        this.info.adicao[1].title = item.outros ?? 0;
                    }
                    if(item.id_tipo_movimentacao == 2){
                        this.info.descarte[0].title = item.nao_aplicado ?? 0;
                        this.info.descarte[1].title = item.danificacao ?? 0;
                        this.info.descarte[2].title = item.validade_expirada ?? 0;
                        this.info.descarte[3].title = item.refrigeracao_inadequada ?? 0;
                        this.info.descarte[4].title = item.outros ?? 0;
                    }
                });
            })
        },
        calcTotalDoses(array){
            return array.reduce((a, b) => +a + +b, 0)
        },
        loadData(filterData = null) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            const parameters = {
                id_unidade_origem: this.search.unidade_operacional ? this.search.unidade_operacional.id_unidade : '',
                emissao: this.input.data_ajuste ? this.search.data_ajuste : '',
                numero_lote: this.search.lote,
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order,
                tipo: this.search.tipo.length ? this.formatTipoSearch(this.search.tipo) :[2,3]
            }

            this.filtering = true;

            this.$http.get(this.$api.movimentacao(), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;

                this.without = false;
                if(this.table.items.length == 0) {
                    this.without = true;
                }

                this.loadingData = false;

                this.filtering = false;
                this.startPagination(data);
            })
        },
        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;

            this.loadData();
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.loadData();
        },

        updateTableSize(event) {
            if(!event) {
                this.paginationData.defaultSize = 10;
            }
            this.filtering = true;

            this.paginationData.currentPage = 1;
            this.loadData();
        },
        filter() {
            if(this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
                this.withoutColab = false;
                
                clearTimeout(this.timeID);
                this.timeID = setTimeout(() => {
                    this.filtering = true;
                    this.loadData();
                    this.loadCardsInfo();
                }, 500);
            }
        },
        cleanSearch() {
            this.search.unidade_operacional = ''
            this.search.tipo = []
            this.input.data_ajuste = ''
            this.search.lote = ''
        },
        formatTipoSearch(data){
            let arrayTipo = [];
            if(data){
                data.map(tipo =>{
                    arrayTipo.push(tipo.id_tipo_movimentacao);
                })
            }
            return arrayTipo;
        },
        redirectToShow(item) {
            this.$router.push(
                { 
                    name: 'ajuste-estoque-show', 
                    params: {
                        idMovimentacao: item.id_movimentacao,
                    }
                }
            );
        },
        formatString(string){
            const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
            return formatted.toLowerCase();
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
        openModalAction(type) {
            this.modalAction.showModal = true;
            this.modalAction.typeModal = type;
        },
        closeModalAction() {
            this.modalAction.showModal = false;
        },
        openModalSucesso() {
            this.modalAction.showModal = false;
            this.modalSucesso.showModal = true;
        },
        closeModalSucesso() {
            this.loadData();
            this.loadCardsInfo();
            this.modalSucesso.showModal = false;
        },
        setPicker() {
            this.input.data_ajuste = moment(this.search.data_ajuste).format('DD/MM/YYYY');
        },
        setInput() {
            this.search.data_ajuste = moment(this.input.data_ajuste, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
    }
    
}
</script>

<style>
.font_size_label_estoque{
    font-size: 15px;
    margin-top: -5px;
}
.altura_max_scrollbar{
    max-height:100px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.hidden_overflow_tabela{
    overflow: hidden;
}
.cor_botao{
    color: #2772C0 !important;
    border: 1px solid #2772C0 !important;
}
</style>